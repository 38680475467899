import React from 'react';
import cover from '../../../assets/images/blog/visionar_augmented_reality_how_augmented_reality_really_works.jpg'

export default {
    id: 9,
    date: 'Jan 10, 2020 | by visionar editorial office',
    title: 'How augmented reality works',
    cover: cover,
    body: (
        <div>
            <p>Augmented Reality (AR) is one of the biggest breakthroughs in technological industry and it makes one wonder how Augmented Reality really works? To better understand this scientific advancement, we have to first understand what AR actually is.</p>

            <h3>What is Augmented Reality?</h3>
            <p>Augmented Reality or AR is defined as a direct or indirect view of a physical, real-world environment whose elements are augmented by computer-generated sensory input. Simply put, it incorporates virtual objects such as text, sound or images into the real-time world with the help of AR devices.

                The main type of Augmented Reality technology is trigger based AR, which uses characteristics or ‘stimuli’ such as GPS location, paper markers or object markers that trigger the augmentation. These include:</p>
            
            <ul>
                <li>Marker Based: It is also called as Image Recognition AR. The object is placed in front of the camera, which recognizes it and provides further detailed information as well as 3D version of the object.</li>
                <li>Location Based: Data is collected from surroundings through GPS location and is compared with points of interest located in the surroundings. This data helps to decide where the virtual data should be added to the real world.</li>
                <li>Dynamic Augmentation: It works through object recognition and is triggered by the movement of object through motion tracking. For example, Sephora has developed an AR application. It tracks your face and its motion to apply various filters and degrees of cosmetics.</li>
            </ul>

            <h3>Augmented Reality Devices</h3>
            <p>Augmented Reality works through a number of devices that support AR algorithms. On mobiles, tablets and iPads, the AR technology works through augmented reality apps that allow users to view 3D models of object and are particularly useful for marker based AR.
                On laptops, PCs and televisions, AR technology works through a screen and webcam.</p>
            
            <p>Through head mounted displays, lenses and glasses, you get the ultimate experience of augmented reality as it encompasses your entire field of view and provides a more ‘real’ AR experience.

            </p>

            <h3>Augmented Reality Technologies</h3>
            <p>AR technologies are mainly based on algorithms that operate AR. These allow the AR devices to use artificially intelligent scene recognition, collect data using the cameras, send and process data to visualize the digital models relevant to the real-world environment. Most significant of these technologies include:</p>
            
            <ul>
                <li>S.L.A.M: It stands for Simultaneous Localisation And Mapping. This technology simultaneously maps and localizes the environment it is present in. It uses algorithms to collect and visualize data from surroundings which is distinguished in categories of reference points, such as walls, floor, roof, barriers etc. These reference points are used to map and localize the framework of surroundings and also keep track of its position within that map. Robots use Simultaneous Localisation And Mapping to track themselves within an environment and to move around easily.</li>
                <li>Depth Tracking: It is a process of calculating the distance of the digital object or model from the Augmented Reality device. It uses artificial intelligence to recognize and calculate the distance through various sensors, including the camera.</li>
                <li>Image Processing and Projection: Collecting data using camera lenses and processing. The projection takes this data and projects this data on a surface to view, such as mobile and tablet screens.</li>
            </ul>

            <h3>Augmented Reality Implementation</h3>
            <p>As a breakthrough advancement, Augmented Reality is nowadays implemented in almost all contemporary technological products. Several industries including medicine and healthcare, business, and cars and transportation industries have AR implementations by the industry leaders, which sets them apart from the rest.</p>
        </div>
    )
}