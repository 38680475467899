import React from 'react'
import {Link} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

const ProjectStartArea = () => {
    return (
        <div className="project-start-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <StaticImage src="../../assets/images/project-start1.png" alt="get-started" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>Ready to boost your business?</h2>
                            <p>Start your own project now and unleash your imagination in the world of augmented reality!</p>
                            
                            <Link to="/pricing" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Start Creating
                                <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <StaticImage src="../../assets/images/shape/circle-shape1.png" alt="shape" />
            </div>
        </div>
    )
}

export default ProjectStartArea;