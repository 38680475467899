import React from 'react';
import article001 from './Articles/Article001'
import article002 from './Articles/Article002'
import article003 from './Articles/Article003'
import article004 from './Articles/Article004'
import article005 from './Articles/Article005'
import article006 from './Articles/Article006'
import article007 from './Articles/Article007'
import article008 from './Articles/Article008'
import article009 from './Articles/Article009'
import article010 from './Articles/Article010'
import article011 from './Articles/Article011'
import article012 from './Articles/Article012'
import article013 from './Articles/Article013'
import article014 from './Articles/Article014'

const BlogDetailsContent = ({articleId}) => {
    const RenderArticle = () => {
        let articleNr = parseInt(articleId, 10)
        let articleData = null;

        switch (articleNr) {
            case 1:
                articleData = article001
                break
            case 2:
                articleData = article002
                break
            case 3:
                articleData = article003
                break
            case 4:
                articleData = article004
                break
            case 5:
                articleData = article005
                break
            case 6:
                articleData = article006
                break
            case 7:
                articleData = article007
                break
            case 8:
                articleData = article008
                break
            case 9:
                articleData = article009
                break
            case 10:
                articleData = article010
                break
            case 11:
                articleData = article011
                break
            case 12:
                articleData = article012
                break
            case 13:
                articleData = article013
                break
            case 14:
                articleData = article014
                break
            default:
                articleData = null
        }

        return articleData ? (
            <div>
                <div className="article-image">
                    <img src={articleData.cover} alt="bb" />
                </div>

                <div className="article-content">
                    <h2>{articleData.title}</h2>
                    <div style={{ fontSize: '13px', color: '#7e858c', marginBottom: '16px' }}>{articleData.date}</div>
                    {articleData.body}
                </div>
            </div>
        ) : null
    }

    return (
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="blog-details-desc">
                            <RenderArticle />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent
