import React from 'react';
import cover from '../../../assets/images/blog/visionar_augmented_reality_top-10-ar-sdk_792-352-1.jpg'

export default {
    id: 1,
    date: 'Sep 10, 2019 | by visionar editorial office',
    title: 'Top 10 AR SDK – program the most powerful SDKs to the AR App',
    cover: cover,
    body: (
        <div>
            <p>Modern Augmented Reality SDKs are flexible, work on different platforms and allow you to easily program the required augmented reality app. However, the choice of tools is more than abundant and thus you are spoiled for choice. We looked at the AR SDK market for iOS and Android and give you a quick overview of the best AR app tools available today.</p>

            <h3>Wikitude</h3>
            <p>The Augmented Reality SDK Wikitude leaves only a few wishes unfulfilled. It supports the platforms Android, iOS, Windows Tablets as well as Epson Smart Glasses and covers all major development frameworks from JavaScript API to Cordova. The professional entry into the development environment is uncomplicated, especially for experienced developers and is hardly complicated due to the numerous features. Because Wikitude impresses with its functionality from image and object recognition via 3D markerless tracking to ARKit and ARCore support.</p>

            <h3>Arkit</h3>
            <p>Apple’s ARKit focuses on iOS 11/12 platforms. To create an augmented reality app that works exclusively on later iPhone and iPad hardware, the SDK is enough. It brings a two-dimensional image recognition and a 2D image tracking. ARKit is ideal for simple apps, programming multiplayer games or getting started with the AR app.</p>

            <h3>Arcore</h3>
            <p>ARCore is Google’s answer to ARKit, with the SDK supporting both iOS 11 and Android 7.0 and higher. The augmented reality SDK uses the core functions of motion tracking, understanding the environment by determining the size and position of surfaces as well as the light estimation in order to connect the real and the virtual world. Based on these functionalities, you place virtual objects and texts in a physical environment.</p>

            <h3>Vuforia</h3>
            <p>Vuforia is at the forefront of app development for developers who regularly create an augmented reality app. The SDK supports iOS, Android, Unity Editor and UWP. The apps programmed with Vuforia convince with functionalities such as the recognition of various visual objects or text and environment recognition. Plugins like the powerful Unity plugin and innovative functionalities since version 7.1. complement the Augmented Reality SDK.</p>

            <h3>visionar native SDK</h3>
            <p>With the focus on iOS and Android, AR features are integrated into existing apps. The visionar native SDK is part of the visionar platform which offers a complete solution for app developers by combining the visionar web app with the function of a CMS, the visionar cloud and the development kit. Even without many years of programming experience, AR functions can be easily integrated into existing apps. The content is managed by the app owner. This saves valuable time and programming effort at 100% outcome. Visionar is available as a native SDK for iOS and Android augmented reality apps, and supports AR features such as 2D image recognition, object tracking, video ads in full screen or displaying an image, video, audio, or link on the target.</p>

            <h3>Maxst</h3>
            <p>Maxst supports the platforms iOS, Android, Mac OS and Windows and stands for an uncomplicated and easy-to-use all-in-one solution. Five key features such as image, instant and object trackers as well as visual SLAM and a QR / barcode scanner form the basis for programming a simple AR app.</p>

            <h3>DeepAR</h3>
            <p>The DeepAR SDK focuses on the platforms Android, iOS, WebGL, Windows and PC. As an augmented reality SDK, the tool impresses with its real-time facial and facial feature recognition. The basis for this is machine learning as well as patented data models. Mobile and web apps optimize the precise image reproduction. You develop high-quality facial limes, masks and effects? Then you should select this Augmented Reality SDK.</p>

            <h3>EasyAR</h3>
            <p>EasyAR supports the platforms iOS, Andoid, UWP, Mac, Windows and Unity Editor. While Version 1.3.1 offers only image recognition, Version 2.0 offers a variety of functions ranging from 3D object recognition to environmental perception to the intelligent Smart Glass solution. One argument why you should choose this AR SDK is the intuitive handling and the uncomplicated integrability.</p>

            <h3>ARToolKit</h3>
            <p>ARToolKit is a pure open source tracking library for iOS, Android, Windows, Linux Mac OS and Smart Glasses. With orientation tracking, flat-image tracking, optical stereo and camera calibration, and plug-ins for OpenSceneGraph and Unity, ARToolKit provides interesting functionality for AR App developers.</p>

            <h3>Xzimg</h3>
            <p>Xzimg focuses on the iOS, Android and Windows platforms and includes three main features in the SDK. These are Augmented Vision, Augmented Face and Magic Face. With these core features, your AR app gets great results with real-time face recognition and tracking.</p>
        </div>
    )
}