import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import BlogDetailsContent from '../../components/BlogContent/BlogDetailsContent'
import ProjectStartArea from "../../components/Index/ProjectStartArea";

const BlogDetails = ({articleId}) => {
    return (
        <Layout>
            <Navbar />
            <BlogDetailsContent articleId={articleId} />
            <ProjectStartArea />
            <Footer />
        </Layout>
    )
}

export default BlogDetails;