import React from 'react';
import cover from '../../../assets/images/blog/visionar_augemented_reality_AR-contact-lenses.jpg'

export default {
    id: 12,
    date: 'Mar 10, 2020 | by visionar editorial office',
    title: 'AR Contact Lenses – Sounds Like SciFi, But It Is Closer Than You Think',
    cover: cover,
    body: (
        <div>
            <p>No, this is not a Terminator sequel, augmented reality contact lenses are soon going to become a reality. While they won’t allow you to see through walls, or zoom in 15x, AR technology will give us some interesting options, enhancing our vision.</p>

            <h3>How Do AR And Contact Lenses Work?</h3>
            <p>To understand AR contact lenses, you need to learn about AR glasses, which have already established themselves worldwide, and find uses in different fields. Essentially AR technology lets you “enhance” the real world. This is different from VR (virtual reality), which puts you into a different world, and is more useful for video games and multimedia. AR is much more practical (although it can be used for gaming too), and many industries use the technology to push the boundaries of their trade. For example, you can use AR in business to speed up employee training, or in construction, to let workers see the detailed plan of the building, or to receive live guidance, making them safer on work. However, AR glasses have one flaw—they are usually awkward looking. Also, like regular glasses, they are prone to falling off, and they fog, get dirty and can break. Therefore, if you could have AR contact lenses, you would solve all those issues, and nobody would notice you wear a pair.</p>

            <h3>New Potential Uses</h3>
            <p>Besides the most obvious uses AR technology is known today, such as GPS navigation, texting, or live instructions, AR contact lenses have one potential advantage that is huge—enhancing vision. Users that have poor vision can benefit from this technology, as it can potentially enhance the real-world images, making them brighter/bigger/sharper, helping people see better. Also, scientists are researching possibilities of smart lenses measuring eye pressure, as well as glucose levels, cholesterol and blood pressure, and alcohol levels. All of these parameters are very important health-wise, and tracking them in real-time can do a lot for your health.</p>

            <h3>The Drawback: Not Yet Ready</h3>
            <p>While AR is nothing new, we will have to wait for contact lenses a bit more. The major problem right now is that current models are not comfortable enough, as the material is too thick. However, softer versions are soon going to come into play, and you can expect much more comfortable lenses in the near future. The second problem is independence. AR contact lenses need something to power them and hiding a battery in something that thin is not an easy task. Plus, you need to recharge those batteries, which is not exactly convenient, considering you are wearing lenses on your eyes.</p>

            <h3>Bottom Line: visionar Is Ready</h3>
            <p>While AR contacts are not yet here, visionar is ready to adopt the technology as soon as it starts selling. Until then, we have our SDK, studio, and app, to meet the AR needs of today. We cater to the needs of developers, businesses, agencies, or individual users, providing solutions for a modern AR environment and lifestyle. Check out our showcase and see how you can implement AR in your life or business today.</p>
        </div>
    )
}