import React from 'react';
import cover from '../../../assets/images/blog/visionar_augmented_reality_ar-areas-of-application_792-352-1.jpg'

export default {
    id: 6,
    date: 'Nov 15, 2019 | by visionar editorial office',
    title: 'Augmented Reality Areas of Application – how different industries and business sectors benefit from it',
    cover: cover,
    body: (
        <div>
            <p>Augmented reality is an enrichment for many application areas. Because an AR application is much more than just a technical gimmick for nerds. After all, AR applications quickly became a mega trend in a wide variety of business sectors. As a marketing tool, an Augmented Reality software opens up new ways of communication between companies and customers or it simplifies previously complex processes in production companies. The following examples present an extract of the numerous Augmented Reality application areas.</p>

            <h3>Augmented Reality for Marketing and Sales</h3>
            <p>An Augmented Reality software is a powerful and sustainable marketing and distribution tool. Especially in the end customer business the interaction between product and customer is very important. AR applications make this possible. With no other available technology, it is possible to present the advantages and features of a products as targeted as with an augmented reality application. Also, the consumer has fun to explore products in a playful way.</p>

            <h3>Global players like BMW or IKEA have already discovered the benefits of AR applications:</h3>
            
            <ul>
                <li>IKEA app positions furniture and accessories in the living room of customers.</li>
                <li>BMW and Mercedes customers consider and configure future cars in detail from the inside and the outside.</li>
                <li>Exhibitors on trade shows gain competitive advantages through targeted interaction with potential customers.</li>
            </ul>
            
            
            <p>These examples show how Augmented Reality can have a lasting effect on the individual application areas. Regardless of whether it is an online catalogue or whether the AR application transforms a stand on a trade fair into an interactive center of information: augmented reality is the technology of the future for marketing and sales.</p>

            <h3>Entrepreneurial everyday life with an augmented reality app</h3>
            <p>Not only marketing and sales benefit from this innovative technology. The triumph of the AR software is unstoppable and AR apps are conquering new ground every day. Augmented Reality is on its best way to redefine numerous business processes.
                In maintenance, the AR app is replacing complex machine building plans and explains technicians step by step the correct approach by the use of an activated presentation of every single machine component. In a warehouse augmented reality apps can navigate the user to the correct storage bin and thus save time at the inventory and commissioning.</p>

            <h3>Knowledge transfer with an AR application</h3>
            <p>Augmented Reality Areas of application such as the technical area benefit in many different situations from the interactive presentation. In this way, human beings memorize the functions of single machine components by an interactive presentation better than through purely theoretically conveyed knowledge. Specialized augmented reality software trains production staff in a short amount of time for their new job and a cross location AR app provides all the company’s employees with comprehensive knowledge of complex products. Even in medicine, AR technology is settling down. For example, doctors use the Augmented Reality HoloLens glasses from Microsoft to carry out complicated medical procedures and continue to educate themselves.
                In medical studies, the interactive contents of an AR application support the acquisition of theoretical knowledge. However, the use of an AR software is not limited to universities. The areas of application in schools are almost infinite already and enable students to learn individually according to their abilities. Students control the results of a task in their AR application or see a video detailing the calculation of an equation.</p>
            
            <h3>Augmented Reality – mega trend for companies of all sizes and industries</h3>
            <p>Augmented reality is a mega trend with a deep and lasting effect for branches of all kind. The very high degree of individualization of the innovative solutions are certainly one of the biggest strengths. Above all the education sector benefits to the same extent as the automotive or trade sector. The range of AR applications reaches from cost-effective, powerful solutions for global players to augmented reality applications for small and medium-sized businesses on which visionar specializes in. With a focus on providing AR software for smaller projects, even companies without huge advertising budgets will benefit from the mega-trend augmented reality.</p>
        </div>
    )
}