import React from 'react';
import cover from '../../../assets/images/blog/visionar_augemented_reality_5_creative_marketing-_ideas.jpg'

export default {
    id: 8,
    date: 'Jan 3, 2020 | by visionar editorial office',
    title: '5 creative marketing ideas to impress your clients',
    cover: cover,
    body: (
        <div>
            <p>If you are looking for really effective and creative marketing ideas to give a boost to your business, whether you are a small scale entrepreneur or CEO of an organisation, in this blog we will help you revitalise your business and grab customer traffic for your merchandising. So, let’s show you five innovative and fresh marketing strategies to impress your clients and stand you out from your competitors.</p>

            <h3>1. Content Marketing Tools</h3>
            <p>Your words in the writing are promising for the customers to trust you. Get to know your customer’s demands and needs and pen them out with attractive content ideas. Storytelling is an inspiring strategy that attracts and resonates with your customers and make them feel they’re in.</p>

            <h4>Infographics</h4>
            <p>An information in form of graphics which includes useful templates, graphs, and charts, videos, statistics, maps and histograms which apprises your customers with full visual information of your business. It’s clear and quick information shared in the form of pictures, words, and graphics.</p>

            <h4>Interviews</h4>
            <p>People are curious to know about you, your whereabouts, starting points, and your story. Get open to the scheme of your company, your promotions, and deals and give your customer full authority to get their own plans in.</p>

            <h3>2. Urban marketing</h3>
            <p>Urban marketing is a pronounced marketing approach in direct collaboration with users. It is used in the form of billboards, graffiti, signs, vending machines and more. Some of the commonly used urban marketing strategies are as follows:</p>
            
            <h4>Mural advertising</h4>
            <p>This is a visual marketing tactic of advertising by the artistry of your brand on a public place or wall of a building usually with dense human traffic. It is a sequel to the hand-painted billboards which is a modern updated approach using aesthetic designs to tempt customers.</p>
            
            <h4>Street campaigns</h4>
            <p>Dealing directly with your clients on the streets is a very effective and creative marketing action taken by the brands and companies. These viral marketing campaigns may include</p>
            <ul>
                <li>Colourful and attractive costumes that reflects your brands are used in public dense regions like stadiums, parks and restaurants.</li>
                <li>Celebrities and actors hired to support and use your brand in their performance.</li>
                <li>Company labeled food trucks and tuck shops with offers and promotions.</li>
            </ul>
            
            <h4>Lamppost and manholes</h4>
            <p>Flexes and artwork is used to market brand name on lampposts and manholes to grab attention of riders and pedestrians respectively.</p>
            
            <h3>3. Live Chats & Streams</h3>
            <p>Webinars and live chats help to interact with your customers demand directly. It helps to create straight forward company-client bond which ensures client satisfaction and financial guarantee to your serious customers. Sharing live videos and pictures of ongoing customers, like unpacking their deliveries or functional usage of your product help other customers gain confidence in your product and service.</p>
            
            <h3>4. Contest Marketing.</h3>
            <p>There are various possibilities for contest marketing. The user can participate for example by uploading own contet or by submitting votes. Instagram and facebook provides promotions using photo contest which just takes a few seconds to upload. Submitting votes contest gain quite a lot of entries as they are easy to play some maybe just a button click.</p>
            
            <h3>5. Augmented Reality in Marketing</h3>
            <p>Traditional marketing tools can be used in a creative way just by adding augmented reality as a feature to it. With science and technology augmented reality marketing is an emerging approach to all the above creative market ideas discussed. It is seen as one of the top marketing trends in 2020. Augmented reality apps are user-friendly and for different target groups. It allows the customers to visualise the use of their product even before buying through unique experience with just a tapping of their cell phones via augmented reality marketing app. By adding AR to your corporate identity materials like brochures, logos, business cards etc., it can also help to build up brand awareness or to increase the customer-product engagement. There are already different showcases on the market, how augmented reality can be used in marketing.</p>
            
            <p>The McKinsey Award Creativity Score shows, that creative brands are performing better. This is shown in key KPIs and higher organic revenue growth. There are various ways to stand out from your competitors and to stay in your customers minds. Which one of them is the best opportunity for your business, depends on your product and your target group. In some cases, those creative marketing ideas can also be combined. An example could be mural artworks or contests combined with an augmented reality feature.

                Get more information about how augmented reality can be used in marketing.</p>
        </div>
    )
}